<template>
  <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp"
             app
             color="primary"
             dark>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    <v-avatar size="32px"
              tile
              item>
      <v-img src="/img/logos/SB_logo_badge_32.png"
             alt="SB_Badge" />
    </v-avatar>
    <v-toolbar-title style="width: 300px"
                     class="ml-0 pl-1">
      <span class="font-weight-bold">Standard Bank</span>
    </v-toolbar-title>
    <v-spacer />
    <v-tooltip v-if="envMessage"
               bottom>
      <template #activator="{on}">
        <v-chip label
                large
                :color="getEnvColor()"
                class="mr-5"
                v-on="on">
          {{ environment }} Mode
        </v-chip>
      </template>
      <span>Currently running in {{ environment }} mode.</span>
    </v-tooltip>

    <div v-if="authUser"
         class="flex-grow">
      <v-menu v-if="isDevelopment"
              offset-y>
        <template #activator="{on}">
          <v-btn icon>
            <v-icon v-on="on">
              mdi-account
            </v-icon>
          </v-btn>
        </template>
        <v-list class="overflow-y-auto"
                height="30rem">
          <v-list-item v-for="(item, index) in DUMMY_USERS"
                       :key="index"
                       @click="changeUser(item)">
            <v-list-item-title>{{ item.displayName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-icon v-else
              left>
        mdi-account
      </v-icon>

      <span class="pr-4 mt-3">{{ authUser.displayName }}</span>

      <v-tooltip bottom>
        <template #activator="{on, attrs}">
          <span v-bind="attrs"
                v-on="on">
            <span class="mt-3">ID: {{ authUser.username }}</span>
          </span>
        </template>
        <span>Selected Merchant ID</span>
      </v-tooltip>

      <v-menu v-if="authUser.userIds.length > 1"
              offset-y>
        <template #activator="{on}">
          <v-btn icon>
            <v-icon v-on="on">
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in authUser.userIds"
                       :key="index"
                       @click="changeUserID(item.id)">
            <v-list-item-title>{{ item.id }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { DUMMY_USERS } from '@/config/dummyData';
import { setUserGroups } from '@/workers/session.worker';

export default {
  data() {
    return {
      DUMMY_USERS,
    };
  },

  computed: {
    drawer: {
      get() {
        return this.$store.state.userSettings.sidebar;
      },
      set(value) {
        this.$store.commit('userSettings/sidebar', value);
      },
    },

    envMessage() {
      return this.environment !== 'Production';
    },
  },

  methods: {
    getEnvColor() {
      let color;

      switch (this.environment) {
        case 'Development':
          color = 'secondary';
          break;
        case 'Testing':
          color = 'warning';
          break;
        case 'Staging':
          color = 'deep-purple darken-1';
          break;
        default:
          break;
      }

      return color;
    },

    async changeUser(user) {
      this.$store.commit('session/user', user);
      await setUserGroups(user.groups);
    },

    async changeUserID(id) {
      this.authUser.username = id;
      this.$store.commit('session/user', this.authUser);
      if (this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home' });
      }
    },
  },
};
</script>
