<template>
  <v-footer dark
            inset
            color="accent"
            class="mt-5">
    <v-container class="sb-max">
      <v-row no-gutters
             justify="center"
             class="text-body-2">
        <v-col cols="12"
               md="8"
               :order="isMobile ? 12 : 1">
          <v-row>
            <v-col cols="12"
                   sm="1"
                   :class="{'py-1': isMobile}">
              <a target="_bank"
                 rel="noopener noreferrer"
                 class="footer-link"
                 href="https://www.standardbank.co.za/southafrica/personal/about-us/legal">
                Legal
              </a>
            </v-col>
            <v-col cols="12"
                   sm="2"
                   :class="{'text-center': !isMobile, 'py-1': isMobile}">
              <a target="_bank"
                 rel="noopener noreferrer"
                 class="footer-link"
                 href="https://www.standardbank.co.za/southafrica/personal/about-us/regulatory">
                Regulatory
              </a>
            </v-col>
            <v-col cols="12"
                   sm="2"
                   :class="{'text-center': !isMobile, 'py-1': isMobile}">
              <a target="_bank"
                 rel="noopener noreferrer"
                 class="footer-link"
                 href="https://www.standardbank.co.za/southafrica/personal/products-and-services/security-centre/bank-safely">
                Security Centre
              </a>
            </v-col>
            <v-col cols="12"
                   sm="3"
                   :class="{'text-center': !isMobile, 'py-1': isMobile}">
              <a target="_bank"
                 rel="noopener noreferrer"
                 class="footer-link"
                 href="https://www.standardbank.co.za/southafrica/personal/about-us/terms-and-conditions">
                Terms &amp; Conditions
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="text-body-2">Standard Bank is a licensed financial services provider in terms
                of the Financial Advisory and Intermediary Services Act and a
                registered credit provider in terms of the National Credit Act,
                registration number NCRCP15</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12"
               md="4"
               :order="isMobile ? 1 : 12"
               :class="{'text-right': !isMobile}">
          <span v-if="!isMobile">
            <a target="_blank"
               href="https://www.facebook.com/StandardBankSA"
               rel="noopener noreferrer"
               class="footer-link px-2">
              <v-icon>mdi-facebook</v-icon>
            </a>
            <a target="_blank"
               href="http://www.twitter.com/standardbankza"
               rel="noopener noreferrer"
               class="footer-link px-2">
              <v-icon>mdi-twitter</v-icon>
            </a>
            <a target="_blank"
               href="https://www.linkedin.com/company/standard-bank-south-africa"
               rel="noopener noreferrer"
               class="footer-link px-2">
              <v-icon>mdi-linkedin</v-icon>
            </a>
            <a target="_blank"
               href="https://www.youtube.com/channel/UC_oz5no5MIucu45I4PO9nCQ"
               rel="noopener noreferrer"
               class="footer-link px-2">
              <v-icon>mdi-youtube</v-icon>
            </a>

          </span>
          <v-row v-else
                 class="justify-space-between pa-3">
            <a target="_blank"
               href="https://www.facebook.com/StandardBankSA"
               rel="noopener noreferrer"
               class="footer-link px-2">
              <v-icon>mdi-facebook</v-icon>
            </a>
            <a target="_blank"
               href="http://www.twitter.com/standardbankza"
               rel="noopener noreferrer"
               class="footer-link px-2">
              <v-icon>mdi-twitter</v-icon>
            </a>
            <a target="_blank"
               href="https://www.linkedin.com/company/standard-bank-south-africa"
               rel="noopener noreferrer"
               class="footer-link px-2">
              <v-icon>mdi-linkedin</v-icon>
            </a>
            <a target="_blank"
               href="https://www.youtube.com/channel/UC_oz5no5MIucu45I4PO9nCQ"
               rel="noopener noreferrer"
               class="footer-link px-2">
              <v-icon>mdi-youtube</v-icon>
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },

  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
  .footer-link {
    text-decoration: none;
    color: #fff;
  }
</style>
