<template>
  <v-sheet v-if="deferredPrompt && !isInstalled"
           tile
           dark
           width="100%"
           color="secondary"
           class="pa-3 mt-5">
    <v-row>
      <v-col cols="3"
             justify="center">
        <v-img src="/img/logos/logo_badge60.png"
               alt="SB_Badge" />
      </v-col>
      <v-col cols="9"
             class="pl-0">
        <div>
          <div class="text-subtitle-1 white--text">
            Find what you need faster
          </div>
          <div class="text-body-2 white--text">
            by installing to your device
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn text
             class="mr-3"
             @click="installApp()">
        Install
      </v-btn>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      deferredPrompt: null,
    };
  },

  computed: {
    isInstalled() {
      return window.matchMedia('(display-mode: standalone)').matches;
    },
  },

  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent default prompt
      e.preventDefault();

      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
  },

  methods: {
    installApp() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((result) => {
          if (result.outcome === 'accepted') {
            this.$notify.success('Application installed successfully');
          }
        });
      }
    },
  },
};
</script>
