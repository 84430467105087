var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"primary","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-avatar',{attrs:{"size":"32px","tile":"","item":""}},[_c('v-img',{attrs:{"src":"/img/logos/SB_logo_badge_32.png","alt":"SB_Badge"}})],1),_c('v-toolbar-title',{staticClass:"ml-0 pl-1",staticStyle:{"width":"300px"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Standard Bank")])]),_c('v-spacer'),(_vm.envMessage)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-5",attrs:{"label":"","large":"","color":_vm.getEnvColor()}},on),[_vm._v(" "+_vm._s(_vm.environment)+" Mode ")])]}}],null,false,3094976860)},[_c('span',[_vm._v("Currently running in "+_vm._s(_vm.environment)+" mode.")])]):_vm._e(),(_vm.authUser)?_c('div',{staticClass:"flex-grow"},[(_vm.isDevelopment)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g({},on),[_vm._v(" mdi-account ")])],1)]}}],null,false,1813753324)},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"height":"30rem"}},_vm._l((_vm.DUMMY_USERS),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeUser(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.displayName))])],1)}),1)],1):_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account ")]),_c('span',{staticClass:"pr-4 mt-3"},[_vm._v(_vm._s(_vm.authUser.displayName))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',{staticClass:"mt-3"},[_vm._v("ID: "+_vm._s(_vm.authUser.username))])])]}}],null,false,3266472021)},[_c('span',[_vm._v("Selected Merchant ID")])]),(_vm.authUser.userIds.length > 1)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g({},on),[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,520206937)},[_c('v-list',_vm._l((_vm.authUser.userIds),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeUserID(item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.id))])],1)}),1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }