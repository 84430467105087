<template>
  <v-navigation-drawer v-model="drawer"
                       :clipped="$vuetify.breakpoint.lgAndUp"
                       app
                       width="300">
    <v-list dense
            nav>
      <template v-for="item in menuItems">
        <v-subheader v-if="item.heading"
                     :key="item.heading">
          {{ item.heading }}
        </v-subheader>
        <v-list-group v-else-if="item.children && hasPermission(item.roles)"
                      :key="item.text"
                      :prepend-icon="item.icon"
                      :value="item.expanded"
                      no-action
                      color="primary">
          <template #activator>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </template>
          <template v-for="(child, i) in item.children">
            <v-list-item v-if="hasPermission(child.roles)"
                         :key="i"
                         :to="child.path"
                         :disabled="child.disabled"
                         exact>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-group>
        <template v-else>
          <v-list-item v-if="hasPermission(item.roles)"
                       :key="item.text"
                       :to="item.path"
                       :disabled="item.disabled"
                       color="primary"
                       exact>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
      <v-list-item @click="logout">
        <v-list-item-action>
          <v-icon>mdi-power</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <sb-install-prompt />
      <div class="pa-2 text-center">
        Version: {{ version }}
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions } from 'vuex';
import SbInstallPrompt from '@/components/pwa/SbInstallPrompt';

import MENU_ITEMS from '@/config/menuItems';

export default {
  components: {
    SbInstallPrompt,
  },

  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      menuItems: MENU_ITEMS,
    };
  },

  computed: {
    drawer: {
      get() {
        return this.$store.state.userSettings.sidebar;
      },
      set(value) {
        this.$store.commit('userSettings/sidebar', value);
      },
    },
  },

  methods: {
    ...mapActions('session', ['logout']),

    hasPermission(roles) {
      return roles.filter((x) => this.authUser.groups.includes(x)).length > 0 || roles[0] === 'ALL';
    },
  },
};
</script>
